import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/blackavocado/src/components/command-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blackavocado/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/vercel/path0/apps/blackavocado/src/utils/react-query/query-client-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts/index.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"interFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./geist-regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./geist-medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./geist-bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-geist\",\"fallback\":[\"sans-serif\",\"system-ui\",\"arial\"]}],\"variableName\":\"geistFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["getRandomInt"] */ "/vercel/path0/packages/common-helpers/src/random/getRandomInt.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/styles.css");
